<template>
  <LiefengContent>
    <template v-slot:title>档案管理</template>
    <template v-slot:toolsbarRight>
      <Form :model="searchForm" :label-colon="true" style="width: 624px" :inline="true">
        <FormItem label="姓名">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.name"
            placeholder="请输入姓名"
            style="width: 150px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            :maxlength="20"
            v-model.trim="searchForm.mobile"
            placeholder="请输入手机号"
            style="width: 150px"
          ></Input>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search-outline"
          @click="search"
          style="margin-right: 10px"
        >查询</Button>
        <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
        <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchForm" :label-colon="true">
            <FormItem label="姓名">
              <Input v-model.trim="searchForm.name" placeholder="请输入姓名" :maxlength="20"></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input :maxlength="20" v-model.trim="searchForm.mobile" placeholder="请输入手机号"></Input>
            </FormItem>
            <FormItem label="档案编号">
              <Input
                :maxlength="20"
                v-model.trim="searchForm.archiveNum"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="所属社区">
              <Input :maxlength="20" v-model.trim="searchForm.orgName" placeholder="请输入所属社区"></Input>
            </FormItem>
            <FormItem label="分类">
              <Select v-model="searchForm.difficultType">
                <Option
                  v-for="item in classificationList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem label="分级">
              <Select v-model="searchForm.difficultLevel">
                <Option
                  v-for="item in levelList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem label="跟进人">
              <Input :maxlength="20" v-model.trim="searchForm.staffName" placeholder="请输入跟进人"></Input>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false">取消</Button>
            <Button type="primary" @click="search">查询</Button>
          </div>
        </Drawer>
      </Form>
      <Button type="warning" icon="ios-cloud-upload-outline" @click="uploadExcel">导入</Button>
      <Button type="error" icon="ios-cloud-download-outline" @click="exportExcel">导出</Button>
      <Button type="primary" @click="$router.push('/archivesindex')" icon="ios-arrow-back">返回</Button>
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>

      <!-- 查看弹窗 -->
      <LiefengModal
        title="查看信息档案"
        :value="seeDemandStatus"
        :fullscreen="true"
        @input="seeDemandStatusFn"
      >
        <template v-slot:contentarea>
          <SeeDemand
            v-if="seeDemandStatus"
            tabName="name2"
            :showReferral="showReferral"
            :info="info"
          ></SeeDemand>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="seeDemandStatusFn(false)">关闭</Button>
        </template>
      </LiefengModal>

      <!-- 完善信息档案弹窗 -->
      <LiefengModal
        title="完善信息档案"
        :value="changeArchiveStatus"
        :fullscreen="true"
        @input="changeArchiveStatusFn"
        class="add-change"
      >
        <template v-slot:contentarea>
          <ChangeArchives
            ref="changeArchives"
            @saveSuccess="saveSuccess"
            v-if="changeArchiveStatus"
            :userId="userId"
            :info="info"
          ></ChangeArchives>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cacelModalTip = true;
              changeArchiveStatusFn(false);
            "
            style="margin-right: 10px"
          >关闭</Button>
          <Button type="primary" @click="saveArchives">保存</Button>
        </template>
      </LiefengModal>

      <!-- 转介 -->
      <LiefengModal
        title="转介服务登记表"
        :value="referralStatus"
        :fullscreen="true"
        @input="referralStatusFn"
        class="referrak"
      >
        <template v-slot:contentarea>
          <Referral
            ref="referral"
            @saveSuccess="saveSuccessReferral"
            v-if="referralStatus"
            :infoId="info"
            :archiveId="info"
          ></Referral>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cacelModalTip = true;
              referralStatusFn(false);
            "
            style="margin-right: 10px"
          >关闭</Button>
          <Button type="primary" @click="saveReferral">保存</Button>
        </template>
      </LiefengModal>
      <!-- 导入excel弹窗 -->
      <LiefengModal title="导入档案" width="40%" :value="excelStatus" @input="excelModalData">
        <template v-slot:contentarea>
          <Form :model="formValidate" :rules="ruleForm" :label-colon="true" :label-width="100">
            <FormItem prop="deptCode" label="所属部门" style="width: 100%; margin: 10px 0">
              <Select
                v-model="formValidate.deptCode"
                placeholder="请选择所属部门"
                style="width: 100%"
                filterable
                @on-change="selectDepartment"
              >
                <Option
                  v-for="(item, index) in departmentList"
                  :key="index"
                  :value="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem prop="staffId" label="主责社工" style="width: 100%">
              <Select
                v-model="formValidate.staffId"
                placeholder="请先选择所属部门,再选择部门下的社工"
                style="width: 100%"
                filterable
                @on-change="selectMarital"
              >
                <Option
                  v-for="(item, index) in socialWorkerList"
                  :key="index"
                  :value="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </Form>
          <Divider orientation="left">数据上传</Divider>
          <Upload
            ref="excelUpload"
            type="drag"
            :action="action"
            name="file"
            :show-upload-list="false"
            :before-upload="beforeExcelUpload"
            :headers="uploadHeader"
            :on-success="myExcel"
            :on-error="errorUpload"
            :data="uploadData"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
          <!-- <div v-html="errorContent"></div> -->
          <div style="text-align: center; margin: 20px 0">
            <Button type="primary" style="margin-right: 20px">
              <a href="./excelfiles/archives.xlsx" download="档案导入模板.xlsx" style="color: #fff">模板下载</a>
            </Button>
            <Button type="info" @click="submitExcel">确认导入</Button>
          </div>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/archivesmanage")

import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import SeeDemand from "@/views/archives/childrens/seeDemand";
import ChangeArchives from "@/views/archives/childrens/changeArchives";
import Referral from "@/views/archives/childrens/referral";
import qs from "qs";
export default {
  data() {
    return {
      searchForm: {},
      selectQuery: false, //搜索抽屉
      //搜索分类
      classificationList: [
        { label: "低保低收入对象", value: "1" },
        { label: "特困人员", value: "2" },
        { label: "留守老年人", value: "3" },
        { label: "独居孤寡老人", value: "4" },
        { label: "困境儿童", value: "5" },
        { label: "贫困重度残疾人", value: "6" },
        { label: "其他", value: "7" }
      ],
      //搜索分级
      levelList: [
        { label: "一级", value: "1" },
        { label: "二级", value: "2" },
        { label: "三级", value: "3" },
        { label: "四级", value: "4" }
      ],
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "档案编号",
          key: "archiveNum",
          minWidth: 100,
          align: "center"
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
          align: "center"
        },
        {
          title: "手机号",
          key: "mobile",
          width: 150,
          align: "center"
        },
        {
          title: "所属社区",
          key: "orgName",
          minWidth: 150,
          align: "center"
        },
        {
          title: "分类/人员类别",
          key: "difficultType",
          minWidth: 150,
          align: "center"
        },
        {
          title: "分级",
          key: "difficultLevel",
          width: 120,
          align: "center"
        },
        {
          title: "状态",
          key: "stageCode",
          width: 150,
          align: "center"
        },
        {
          title: "跟进人",
          key: "staffName",
          width: 120,
          align: "center"
        },
        {
          title: "操作",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true
                  }
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down"
                      }
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list"
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.showReferral =
                                params.row.stageCode == "已转介" ? true : false;
                              this.seeDemand(params.row.archiveId);
                            }
                          },
                          style: {
                            textAlign: "center"
                          }
                        },
                        "查看所有信息"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.userId = params.row.userId;
                              this.changeArchive(params.row.archiveId);
                            }
                          },
                          style: {
                            textAlign: "center",
                            display:
                              params.row.stageCode == "已转介" ||
                              params.row.stageCode == "停止服务"
                                ? "none"
                                : ""
                          }
                        },
                        "完善修改档案"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.delDemand(params.row.archiveId);
                            }
                          },
                          style: {
                            textAlign: "center"
                          }
                        },
                        "删除档案"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.referral(params.row.archiveId);
                            }
                          },
                          style: {
                            textAlign: "center",
                            display:
                              params.row.stageCode == "已转介" ||
                              params.row.stageCode == "停止服务"
                                ? "none"
                                : ""
                          }
                        },
                        "转介"
                      )
                    ]
                  )
                ]
              )
            ]);
          }
        }
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      //查看
      seeDemandStatus: false,
      info: "",
      showReferral: false,

      //完善
      changeArchiveStatus: false,
      userId: "",

      //转介
      referralStatus: false,
      cacelModalTip: false, //false代表不提示关闭提示

      // 导入
      excelStatus: false,
      socialWorkerList: [],
      formValidate: { staffId: "", staffName: "", deptCode: "" },
      //excel文件数据
      excelFile: null,
      uploadData: {},
      errorContent: "",
      action: "",
      ruleForm: {
        staffId: [
          { required: true, message: "请选择主责社工", trigger: "change" }
        ],
        deptCode: [
          { required: true, message: "请选择所属部门", trigger: "change" }
        ]
      },
      departmentList: [], //所属部门
      uploadHeader: {},

      // 导出
      tableSeleteList: [],
      exportSelectForm: {}
    };
  },
  methods: {
    // 表格选择
    tableSelect(arr) {
      this.tableSeleteList = [];
      arr.map(item => {
        this.tableSeleteList.push(item.archiveId);
      });
    },
    //确认导入
    submitExcel() {
      this.uploadData.deptCode = this.formValidate.deptCode;
      if (
        this.uploadData.deptCode == undefined ||
        this.uploadData.deptCode == ""
      ) {
        this.$Message.error({
          background: true,
          content: "请选择所属部门"
        });
        return;
      }
      if (
        this.uploadData.staffId == undefined ||
        this.uploadData.staffId == ""
      ) {
        this.$Message.error({
          background: true,
          content: "请选择主责社工"
        });
        return;
      }
      if (!this.excelFile) {
        this.$Message.error({
          background: true,
          content: "请选择导入文件"
        });
        return;
      }
      this.$Message.loading({
        content: "正在上传文件，请稍等...",
        duration: 0
      });
      this.$refs.excelUpload.post(this.excelFile);
    },
    //导入excel格式错误
    uploadFormatError(file) {
      this.$Message.destroy();
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件"
      });
    },
    //excel文件上传成功后执行
    myExcel(res) {
      this.$Message.destroy();
      if (res.code == 200 && res.dataList.length == 0) {
        this.$Message.success({
          background: true,
          content: "上传成功！"
        });
        this.excelStatus = false;
        this.hadlePageSize({
          pageSize: this.pageSize,
          page: this.page
        });
      } else if (res.code == 200 && res.dataList.length > 0) {
        this.errorContent = "";
        res.dataList.map((item, index) => {
          this.errorContent += `<div style="color:red">${
            item.number != null ? "第" + (item.number + 4) + "行:" : ""
          }
          ${item.errorCode}</div>`;
        });
        this.$Notice.success({
          title: "其他数据正常导入",
          desc: this.errorContent,
          duration: 0
        });
        this.excelStatus = false;
        this.hadlePageSize({
          pageSize: this.pageSize,
          page: this.page
        });
      } else {
        this.errorContent = "";
        res.dataList.map((item, index) => {
          this.errorContent += `<div style="color:red">第${item.number +
            4}行：${item.errorCode}</div>`;
        });
        this.$Notice.error({
          title: "导入失败",
          desc: this.errorContent,
          duration: 0
        });
      }
    },
    errorUpload() {
      this.$Message.destroy();
      this.$Notice.error({
        title: "导入失败",
        desc: "",
        duration: 0
      });
    },
    //上传之前
    beforeExcelUpload(file) {
      this.action =
        window.vue.getProxy()["/archives"].target +
        "/upload/archiveImport/importArchive";
      this.uploadData.operatorName = parent.vue.loginInfo.userinfo.realName;
      this.uploadData.operatorId = parent.vue.loginInfo.userinfo.custGlobalId;
      this.uploadData.oemCode = parent.vue.oemInfo.oemCode;
      this.excelFile = file;
      this.uploadHeader = {
        openId: parent.vue.loginInfo.userinfo.openId,
        Authorization:window.sessionStorage.getItem('accessToken')
      };
      return false;
    },
    // 选择部门，加载社工
    selectDepartment(code) {
      //获取主责社工列表
      this.$get("/datamsg/api/pc/staff/selectDeptUserByDeptCode", {
        deptCode: code,
        oemCode: parent.vue.oemInfo.oemCode
      }).then(res => {
        if (res) {
          this.socialWorkerList = res.map(item => {
            return {
              value: item.custGlobalId,
              label: item.name
            };
          });
        }
      });
    },
    //选择主责社工
    selectMarital(code) {
      this.uploadData.staffId = code;
      this.socialWorkerList.map((item, index) => {
        if (item.value == code) {
          this.uploadData.staffName = item.label;
        }
      });
    },
    async uploadExcel() {
      this.$Message.loading({
        content: "正在加载数据...",
        duration: 0
      });
      if (this.departmentList.length == 0) {
        //获取所属部门
        await this.$get("/gx/pc/staff/selectUserPermissionDeptList", {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode
        }).then(res => {
          if (res.code == 200 && res.dataList) {
            this.departmentList = res.dataList.map(item => {
              return {
                value: item.orgCode,
                label: item.orgName
              };
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        });
      }
      this.$Message.destroy();
      this.excelStatus = true;
    },
    excelModalData(status) {
      this.excelFile = null;
      this.excelStatus = status;
    },
    // 导出
    exportExcel() {
      this.$Message.loading({
        content: "正在导出数据，请稍等...",
        duration: 0
      });
      this.axios({
        url:
          window.vue.getProxy()["/archives"].target +
          "/upload/archiveImport/exportArchive",
        data: qs.stringify({
          archiveIds: this.tableSeleteList.join(","),
          ...this.exportSelectForm,
          oemCode: parent.vue.oemInfo.oemCode,
          operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
          operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode
        }),
        method: "POST",
        responseType: "blob",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization":window.sessionStorage.getItem('accessToken')
        }
      }).then(res => {
        const blob = new Blob([res.data]);
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "档案.xlsx";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        this.$Message.destroy();
      });
    },
    exportModalFn(status) {
      this.exportStatus = status;
    },
    // 删除
    delDemand(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除数据，是否继续？</p>",
        onOk: () => {
          this.$post("/archives/api/pc/archiveUser/deleteArchive", {
            archiveId: id
          }).then(res => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功"
              });
              this.hadlePageSize({
                page: this.page,
                pageSize: this.pageSize
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          });
        }
      });
    },
    saveSuccess() {
      this.cacelModalTip = false;
      this.changeArchiveStatusFn(false);
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    //完善
    changeArchiveStatusFn(status) {
      if (!status && this.cacelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          className: "vertical-center-modal",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.changeArchiveStatus = status;
          }
        });
      } else {
        this.changeArchiveStatus = status;
      }
    },
    changeArchive(id) {
      this.info = id;
      this.$Message.loading({
        content: "正在加载数据...",
        duration: 0
      });
      this.changeArchiveStatus = true;
    },
    //保存
    saveArchives() {
      this.$refs.changeArchives.saveFn();
    },
    //查看
    seeDemand(id) {
      this.info = id;
      this.$Message.loading({
        content: "正在加载数据...",
        duration: 0
      });
      this.seeDemandStatus = true;
    },
    seeDemandStatusFn(status) {
      this.seeDemandStatus = status;
    },
    //重置
    reset() {
      this.searchForm = {};
      this.exportSelectForm = {};
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    //搜索
    search() {
      this.exportSelectForm = JSON.parse(JSON.stringify(this.searchForm));
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/userNeedy/archiveQuestionPage", {
        ...this.searchForm,
        page: obj.page,
        pageSize: obj.pageSize,
        oemCode: parent.vue.oemInfo.oemCode,
        operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode
      })
        .then(res => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item, index) => {
              switch (item.difficultType) {
                case "1":
                  item.difficultType = "低保低收入对象";
                  break;
                case "2":
                  item.difficultType = "特困人员";
                  break;
                case "3":
                  item.difficultType = "留守老年人";
                  break;
                case "4":
                  item.difficultType = "独居孤寡老人";
                  break;
                case "5":
                  item.difficultType = "困境儿童";
                  break;
                case "6":
                  item.difficultType = "贫困重度残疾人";
                  break;
                case "7":
                  item.difficultType = "其他";
                  break;
              }
              switch (item.stageCode) {
                case "1":
                  item.stageCode = "需求评估";
                  break;
                case "2":
                  item.stageCode = "建立档案";
                  break;
                case "3":
                  item.stageCode = "服务阶段";
                  break;
                case "4":
                  item.stageCode = "已转介";
                  break;
                case "5":
                  item.stageCode = "停止服务";
                  break;
              }
              switch (item.difficultLevel) {
                case "1":
                  item.difficultLevel = "一级";
                  break;
                case "2":
                  item.difficultLevel = "二级";
                  break;
                case "3":
                  item.difficultLevel = "三级";
                  break;
                case "4":
                  item.difficultLevel = "四级";
                  break;
              }
            });
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.page = res.currentPage;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //转介
    referralStatusFn(status) {
      if (!status && this.cacelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          className: "vertical-center-modal",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.referralStatus = status;
          }
        });
      } else {
        this.referralStatus = status;
      }
    },
    saveReferral() {
      this.$refs.referral.save("demand");
    },
    referral(id) {
      this.info = id;
      this.referralStatus = true;
    },
    saveSuccessReferral() {
      this.cacelModalTip = false;
      this.referralStatusFn(false);
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    }
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    SeeDemand,
    ChangeArchives,
    Referral
  }
};
</script>

<style scoped lang="less">
/deep/.ivu-form-item {
  margin-bottom: 0;
  width: 210px;
}
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.add-change,
.referrak {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
</style>
